var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
    },
    [
      _c("div", { staticClass: "row pl-4 pr-4 w-100" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("EditCreateAnecdotalForm", {
              attrs: {
                "edit-anecdotal-event-id": _vm.$route.params.anecdotalEventId,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }