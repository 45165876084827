<template>
<div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
    <div class="row pl-4 pr-4 w-100">
        <div class="col-12">
            <EditCreateAnecdotalForm :edit-anecdotal-event-id="$route.params.anecdotalEventId" />
        </div>
    </div>
</div>
</template>

<script>
import EditCreateAnecdotalForm from '../../components/EditCreateAnecdotalForm.vue';

export default {
    name: 'EditAnecdotal',
    components: {
        EditCreateAnecdotalForm,
    },
};
</script>

<style src="../../css/colorSwatch.css" />

<style scoped>

a.kt-widget__username {
    white-space: nowrap;
}
div.student-container {
    width: 100%;
    max-height: 72px;
    overflow-x: clip;
    overflow-y: scroll;
}
div.student-pic {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.kt-widget__subtitle {
    font-size: 1rem;
}
.kt-widget__subtitle span {
    font-size: 1rem;
}
</style>

<style scoped>
.kt-widget--user-profile-1 span {
  line-height: 0;
}

.kt-widget__username {
  font-size: 14px !important;
}

.kt-widget__media {
  width: 25%;
  max-width: 25%;
}

 textarea {
    width: 100%;
    border: 0 !important;
    outline: none !important;
    background-color: transparent !important;
    color: #a2a5b9;
}
textarea {
    overflow: auto;
    resize: vertical;
}

.kt_chat__tools a i {
    margin-right: 1.1rem;
    color: #cdd1e9;
    font-size: 1.4rem;
}

.kt-chat .kt-chat__input .kt-chat__toolbar {
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

</style>
